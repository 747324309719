import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion, transform, useAnimate } from 'framer-motion'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  const [scope, animate] = useAnimate()
  
  let selectedStyle = {
    color:'white',
    'border-bottom': 'solid 2px #115fff'
  }
  

  const [servicesMenuVisible, setServicesMenuVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const handleClickOutside = (event) => {
    if (!event.target.closest('.navbar-burger-menu1') && !event.target.closest('.navbar-mobile-menu')) {
      setMenuVisible(false);
    }
  };

  useEffect(() => {
    if (menuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuVisible]);

  useEffect(() => {
    scrollTo({top:0, left:0, behavior:'instant'});
  }, []);

  return (
    <nav data-role="Header" className={`navbar-navbar ${props.rootClassName} `}>
      <nav data-role="Header" className="navbar-container10">
        <Link to="/" className="navbar-navlink10">
          <div className="navbar-left">
            <div>
              <img
                alt="image"
                src="/logos/logo%20dark-1000w.png"
                className="navbar-image10"
              />
            </div>
            <img
              alt="image"
              src="/logos/craft-and-forge-homes-logotype-white-rgb-900px-w-72ppi.png"
              className="navbar-image11"
            />
          </div>
        </Link>
        <div className="navbar-right">
          <div className="navbar-links-container1">
            <Link to="/" className="navbar-navlink11 Nav nav" style={location.pathname == '/' ? selectedStyle : {}}>
              Home
            </Link>
            <Link to="/about" className="navbar-navlink12 Nav nav" style={location.pathname == '/about' ? selectedStyle : {}}>
              about
            </Link>
            <div
              onMouseEnter={() => setServicesMenuVisible(true)}
              onMouseLeave={() => setServicesMenuVisible(false)}
              className="navbar-services1"
            >
              <span className="navbar-text10 Nav nav">services</span>
              <motion.div ref={scope} style={{opacity:0, position:'absolute', left:0, right:0, bottom:10, width:30, margin:'auto'}}>
              <img
                alt="image"
                src="/icons/down-arrow-blur-1000w.png"
                className="navbar-image12"
              />
              <img
                alt="image"
                src="/icons/down-arrow-1000w.png"
                className="navbar-image13"
              />
              </motion.div>
            </div>
            <Link to="/contact" className="bluebutton Content">
              <span className="navbar-text11">Contact Us</span>
            </Link>
          </div>
        </div>
        <AnimatePresence>
        {servicesMenuVisible === true && (
          <motion.div key={'dropdown'} className="navbar-services-menu" 
            initial={{opacity: 0, marginTop:'-10px'}}
            animate={{opacity:1, marginTop:0}}
            exit={{opacity: 0, marginTop:'-10px'}}
            transition={{duration:.2}}
            onAnimationStart={def => {
              if(def.opacity == 1){ //entering
                animate(scope.current, {opacity: 1, y:0}, {duration:.2});
              }
              else{ //exiting
                animate(scope.current, {opacity: 0, y: 0}, {duration:.2});
              }
            }}
          >
            <div
              onMouseEnter={() => setServicesMenuVisible(true)}
              onMouseLeave={() => setServicesMenuVisible(false)}
              className="navbar-bg1x4"
            >
              <Link to="/bathrooms" className="navbar-navlink14">
                <div className="navbar-bathrooms1">
                  <img
                    alt="image"
                    src="/photos/home/services/225749094_1025523698256360_6876364482089526948_n_edited.jpg"
                    className="navbar-image14"
                  />
                  <div className="navbar-container11">
                    <span className="navbar-text12 nav" style={location.pathname == '/bathrooms' ? selectedStyle : {}}>Bathrooms</span>
                    <div className="navbar-container12"></div>
                  </div>
                </div>
              </Link>
              <Link to="/kitchens" className="navbar-navlink15">
                <div className="navbar-kitchens1">
                  <img
                    alt="image"
                    src="/photos/home/services/image2-min-600h.jpeg"
                    className="navbar-image15"
                  />
                  <div className="navbar-container13">
                    <span className="navbar-text13" style={location.pathname == '/kitchens' ? selectedStyle : {}}>kitchens</span>
                    <div className="navbar-container14"></div>
                  </div>
                </div>
              </Link>
              <Link to="/renovations-additions" className="navbar-navlink16">
                <div className="navbar-renovationsadditions1">
                  <img
                    alt="image"
                    src="/photos/home/services/railing%203-600h.png"
                    className="navbar-image16"
                  />
                  <div className="navbar-container15">
                    <span className="navbar-text14" style={location.pathname == '/renovations-additions' ? selectedStyle : {}}>
                      <span>
                        renovations
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <br></br>
                      <span>&amp; Additions</span>
                    </span>
                    <div className="navbar-container16"></div>
                  </div>
                </div>
              </Link>
              <Link to="/custom-homes" className="navbar-navlink17">
                <div className="navbar-customhomes1">
                  <img
                    alt="image"
                    src="/photos/home/services/img_3627-600h.jpeg"
                    className="navbar-image17"
                  />
                  <div className="navbar-container17">
                    <span className="navbar-text18" style={location.pathname == '/custom-homes' ? selectedStyle : {}}>
                      <span>custom</span>
                      <br></br>
                      <span>homes</span>
                    </span>
                    <div className="navbar-container18"></div>
                  </div>
                </div>
              </Link>
              <Link to="/adus" className="navbar-navlink18">
                <div className="navbar-customhomes2">
                  <img
                    alt="image"
                    src="/photos/adus/adobestock_959276376.jpeg"
                    className="navbar-image18"
                  />
                  <div className="navbar-container19">
                    <span className="navbar-text22">
                      <span>ADU</span>
                      <span className="navbar-text24">s</span>
                    </span>
                    <div className="navbar-container20"></div>
                  </div>
                </div>
              </Link>
            </div>
            <div
              onMouseEnter={() => setServicesMenuVisible(true)}
              onMouseLeave={() => setServicesMenuVisible(false)}
              className="navbar-bg2x2"
            >
              <Link to="/bathrooms">
                <div className="navbar-bathrooms2">
                  <img
                    alt="image"
                    src="/photos/home/services/225749094_1025523698256360_6876364482089526948_n_edited.jpg"
                    className="navbar-image19"
                  />
                  <div className="navbar-container21">
                    <span className="navbar-text25 nav">Bathrooms</span>
                    <div className="navbar-container22"></div>
                  </div>
                </div>
              </Link>
              <Link to="/kitchens">
                <div className="navbar-kitchens2">
                  <img
                    alt="image"
                    src="/photos/home/services/image2-min-600h.jpeg"
                    className="navbar-image20"
                  />
                  <div className="navbar-container23">
                    <span className="navbar-text26">kitchens</span>
                    <div className="navbar-container24"></div>
                  </div>
                </div>
              </Link>
              <Link to="/renovations-additions">
                <div className="navbar-renovationsadditions2">
                  <img
                    alt="image"
                    src="/photos/home/services/railing%203-600h.png"
                    className="navbar-image21"
                  />
                  <div className="navbar-container25">
                    <span className="navbar-text27">
                      <span>
                        renovations
                        <span
                          dangerouslySetInnerHTML={{
                            __html: ' ',
                          }}
                        />
                      </span>
                      <br></br>
                      <span>&amp; Additions</span>
                    </span>
                    <div className="navbar-container26"></div>
                  </div>
                </div>
              </Link>
              <Link to="/custom-homes">
                <div className="navbar-customhomes3">
                  <img
                    alt="image"
                    src="/photos/home/services/img_3627-600h.jpeg"
                    className="navbar-image22"
                  />
                  <div className="navbar-container27">
                    <span className="navbar-text31">
                      <span>custom</span>
                      <br></br>
                      <span>homes</span>
                    </span>
                    <div className="navbar-container28"></div>
                  </div>
                </div>
              </Link>
            </div>
          </motion.div>
        )}
        </AnimatePresence>
        <div className="navbar-burger-menu1">
          <Link to="/contact" className="Content bluebutton navbar-navlink23">
            <span>Request a quote</span>
          </Link>
          <svg
            viewBox="0 0 1024 1024"
            onClick={() => setMenuVisible(!menuVisible)}
            className="navbar-burger-menu2"
          >
            <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
          </svg>
        </div>
        {menuVisible && (
          <div className="navbar-mobile-menu">
            <div className="navbar-nav">
              <div className="navbar-right-side">
                <div className="navbar-links-container2">
                  <Link to="/" className="navbar-navlink24 nav Nav">
                    Home
                  </Link>
                  <Link to="/about" className="navbar-navlink25 Nav nav">
                    about
                  </Link>
                  <Link to="/contact" className="navbar-navlink26 Nav nav">
                    Contact
                  </Link>
                  <div className="navbar-services2">
                    <span className="navbar-head Nav nav">Services</span>
                    <div className="navbar-services-container">
                      <Link
                        to="/bathrooms"
                        className="navbar-navlink27 Nav nav"
                      >
                        Bathrooms
                      </Link>
                      <Link to="/kitchens" className="navbar-navlink28 Nav nav">
                        Kitchens
                      </Link>
                      <Link
                        to="/renovations-additions"
                        className="navbar-navlink29 Nav nav"
                      >
                        Renovations &amp; Additions
                      </Link>
                      <Link
                        to="/custom-homes"
                        className="navbar-navlink30 nav Nav"
                      >
                        Custom Homes
                      </Link>
                      <Link to="/adus" className="navbar-navlink31 nav">
                        <span className="Nav">ADU</span>
                        <span className="navbar-text37">s</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
      <div className="navbar-blur"></div>
    </nav>
  )
}

Navbar.defaultProps = {
  rootClassName: '',
}

Navbar.propTypes = {
  rootClassName: PropTypes.string,
}

export default Navbar
