import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './about.css'

const About = (props) => {
  return (
    <Wrapper>
    <div className="about-container10">
      <Helmet>
        <title>About - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="About - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar rootClassName="navbarroot-class-name"></Navbar>
      <div className="about-banner">
        <div className="about-hero">
          <div className="about-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="about-max-width1"
              >
                <div className="about-heading-container1">
                  <span className="about-prehead1">about us</span>
                  <span className="about-prehead2">
                    Craft &amp; Forge, previously Northwest unlimited
                  </span>
                  <span className="about-main-title">
                    <span>
                      our passion
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br></br>
                    <span>for precision</span>
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="about-mission">
        <div className="about-max-width2">
          <div className="about-image10"></div>
          <div className="about-float">
            <div className="about-content1">
              <span className="about-prehead3">our mission</span>
              <span className="about-text13 SectionHeading">
                designing with purpose
              </span>
              <span className="about-text14 Content">
                <span>
                  Our vision is to create homes that are more than just spaces,
                  they are reflections of the people who live in them. We
                  believe in designing with integrity, ensuring that every
                  detail, visible or hidden, is crafted to the highest standard.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
              </span>
              <span className="about-text17 Content">
                By fostering genuine relationships with our clients, we turn
                their visions into reality, making every home a place of pride
                and beauty. Through passion, precision, and commitment, we seek
                to redefine what it means to feel truly at home.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="about-metrics1">
        <div className="about-img1">
          <img
            alt="image"
            src="/photos/about/img_0712-1500w.jpg"
            className="about-image11"
          />
          <div className="about-container11"></div>
          <div className="about-container12"></div>
        </div>
        <div className="about-max-width3">
          <div className="about-heading-container2">
            <span className="about-prehead4 Prehead">Our metrics</span>
            <h1 className="about-text18">
              <span>a legacy built</span>
              <br></br>
              <span>on excellence</span>
            </h1>
            <span className="about-text22 copy">
              Craft &amp; Forge has built a reputation for delivering excellence
              through quality craftsmanship and attention to detail. With
              decades of experience, we approach every project with precision,
              consistently exceeding expectations.
            </span>
          </div>
          <div className="about-metrics2">
            <div className="about-metrics-container">
              <div className="about-container13">
                <span className="about-text23 KPI">$7.5m</span>
                <span className="about-text24">+</span>
                <span className="about-text25 Subheading">
                  <span>value</span>
                  <br></br>
                  <span>built</span>
                </span>
              </div>
              <div className="about-container14">
                <span className="about-text29 KPI">80</span>
                <span className="about-text30">+</span>
                <span className="about-text31 Subheading">
                  <span>Transformations</span>
                  <br></br>
                  <span>Completed</span>
                </span>
              </div>
              <div className="about-container15">
                <span className="about-text35 KPI">30</span>
                <span className="about-text36">+</span>
                <span className="about-text37 Subheading">
                  <span>years</span>
                  <br></br>
                  <span>eXPERIENCE</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-bg-container">
        <div className="about-bg"></div>
        <div className="about-team">
          <div className="about-heading-container3">
            <span className="about-prehead5 Prehead">Our team</span>
            <h1 className="about-text41 SectionHeading">
              behind every great build
            </h1>
          </div>
          <div className="about-team-container">
            <div className="about-collumn">
              <div className="about-person1">
                <img
                  alt="image"
                  src="/photos/about/dayne%20cropped1.png"
                  className="about-image12"
                />
                <div className="about-text42">
                  <span className="about-title1 team-title">
                    founding partner
                  </span>
                  <span className="subHead">Dayne Richards</span>
                  <span className="about-bio1 copy">
                    As CEO, Dayne brings expertise in construction logistics and
                    project management, ensuring every remodel is completed with
                    precision. His hands-on approach keeps projects on track,
                    driving Craft &amp; Forge&apos;s exceptional results
                  </span>
                </div>
              </div>
              <div className="about-person2">
                <div className="about-text44">
                  <span className="about-title2 team-title">partner</span>
                  <span className="subHead">Adam McElwee</span>
                  <span className="about-bio2 copy">
                    Adam drives Craft &amp; Forge’s business strategy, managing
                    marketing, client outreach, and operations. His expertise in
                    building partnerships and streamlining processes fuels the
                    company’s growth in competitive markets
                  </span>
                </div>
                <img
                  alt="image"
                  src="/photos/about/adam.png"
                  className="about-image13"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="about-values">
          <img
            alt="image"
            src="/logos/logo%20dark-1000w.png"
            className="about-image14"
          />
          <div className="about-heading-container4">
            <span className="about-prehead6 Prehead">Our values</span>
            <h1 className="about-text46 SectionHeading">
              the heart of our work
            </h1>
          </div>
          <div className="about-value-container">
            <div className="about-container16">
              <span className="about-text47 Subheading">integrity</span>
              <span className="about-text48 copy">
                <span>
                  The most important aspects of your home are most often the
                  unseen ones, sometimes referred to as the “bones” of the home.
                  Integrity to us, means that every detail, seen or unseen,
                  carries the same quality of craftsmanship.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="Content">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="Content"></br>
              </span>
              <img
                alt="image"
                src="/icons/about/build-alt-300h.png"
                className="about-image15"
              />
            </div>
            <div className="about-container17">
              <span className="about-text52 Subheading">commitment</span>
              <span className="about-text53 copy">
                <span>
                  {' '}
                  At the end of the day, all we have is our word. We are
                  committed to following through on our promises and
                  commitments. If we say it, we do it.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="Content">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br className="Content"></br>
              </span>
              <img
                alt="image"
                src="/icons/about/diploma-300h.png"
                className="about-image16"
              />
            </div>
            <div className="about-container18">
              <span className="about-text57 Subheading">relationships</span>
              <img
                alt="image"
                src="/icons/about/users-300h.png"
                className="about-image17"
              />
              <span className="about-text58 copy">
                <span>
                  Relationships are the foundation of our business. we
                </span>
                <span> are not in the building industry, we are in the </span>
                <span>industry of people and where people call home. our</span>
                <span> team, our clients, our community, they are the </span>
                <span>backbone of who we are.</span>
              </span>
            </div>
          </div>
        </div>
        <div className="about-grid-team">
          <animate-on-reveal
            animation="fadeIn"
            duration="1000ms"
            delay="0s"
            direction="normal"
            easing="ease-out"
            iteration="1"
          >
            <div
              data-thq-animate-on-reveal="true"
              className="about-heading-container5"
            >
              <span className="about-prehead7 preHead Prehead">
                our collaborators
              </span>
              <h1 className="about-text64 SectionHeading heading">
                <span className="about-text65">expert partners,</span>
                <span> </span>
                <br></br>
                <span>perfect results</span>
              </h1>
              <span className="about-bio3 copy">
                At Craft &amp; Forge, we believe in the power of collaboration
                to bring your vision to life. By working closely with engineers,
                designers, and specialists, we save you time and eliminate
                stress, ensuring every detail is executed to perfection.
              </span>
            </div>
          </animate-on-reveal>
          <div className="about-mission-small-cover">
            <div className="about-item1">
              <div className="about-image18"></div>
              <div className="about-details1">
                <span className="about-head1 Subheading"> Archtects</span>
                <span className="about-description1">
                  Architects transform your vision into a harmonious design,
                  blending innovation with timeless elegance.
                </span>
              </div>
            </div>
            <div className="about-item2">
              <div className="about-image19"></div>
              <div className="about-details2">
                <span className="about-head2 Subheading">
                  {' '}
                  structural engineers
                </span>
                <span className="about-description2">
                  Structural engineers ensure your home’s foundation is built on
                  safety and strength, with meticulous attention to detail.
                </span>
              </div>
            </div>
            <div className="about-item3">
              <div className="about-image20"></div>
              <div className="about-details3">
                <span className="about-head3 Subheading">
                  {' '}
                  interior designers
                </span>
                <span className="about-description3">
                  Interior designers craft personalized spaces that embody your
                  style and elevate everyday living.
                </span>
              </div>
            </div>
            <div className="about-item4">
              <div className="about-image21"></div>
              <div className="about-details4">
                <span className="about-head4 Subheading">
                  {' '}
                  landscape designers
                </span>
                <span className="about-description4">
                  Landscape designers enhance your outdoor spaces, seamlessly
                  connecting nature with refined architecture.
                </span>
              </div>
            </div>
            <div className="about-item5">
              <div className="about-image22"></div>
              <div className="about-details5">
                <span className="about-head5">
                  <span className="Subheading"> Trade partners</span>
                  <br></br>
                </span>
                <span className="about-description5">
                  Our skilled trade partners, from plumbers to electricians,
                  bring precision and expertise to every essential detail of
                  your home.
                </span>
              </div>
            </div>
            <div className="about-item6">
              <div className="about-image23"></div>
              <div className="about-details6">
                <span className="about-head6 Subheading"> inspectors</span>
                <span className="about-description6">
                  Inspectors ensure every detail meets the highest standards of
                  quality, delivering perfection in every corner.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-cta">
        <div className="about-max-width4">
          <div className="about-heading-container6">
            <div className="about-content2">
              <h1 className="heading SectionHeading about-text71">
                meet with us today
              </h1>
              <span className="about-text72 copy">
                We’re passionate about bringing your vision to life. Whether
                it’s a remodel or a complete transformation, let’s create a
                space that reflects your unique style.
              </span>
              <Link to="/contact" className="bluebutton Content">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
          <div className="about-image24">
            <img
              alt="image"
              src="/photos/about/office%20edited1-1500w.png"
              className="about-image25"
            />
            <div className="about-container19"></div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="about-text74">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="about-text75">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="about-text76">
              <span className="about-text77">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="about-text79">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="about-text80">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="about-text81">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="about-text84">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="about-text85">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="about-text86">
              <span className="about-text87">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        text1311={
          <Fragment>
            <span className="about-text89">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
        rootClassName="footerroot-class-name1"
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default About
