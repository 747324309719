import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer from '../components/footer'
import './ad-us.css'

const ADUs = (props) => {
  return (
    <div className="ad-us-container1">
      <Helmet>
        <title>ADUs - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="ADUs - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="ad-us-hero-fade-single">
        <div className="ad-us-hero">
          <div className="ad-us-content-container">
            <animate-on-reveal
              animation="fadeInUp"
              duration="1000ms"
              delay="0s"
              direction="normal"
              easing="ease-out"
              iteration="1"
            >
              <div
                data-thq-animate-on-reveal="true"
                className="ad-us-max-width1"
              >
                <div className="ad-us-heading-container1">
                  <span className="ad-us-prehead1 preHead">
                    <span>ADU</span>
                    <span className="ad-us-text11">s</span>
                  </span>
                  <span className="ad-us-main-title title">
                    <span>Maximum Space,</span>
                    <br></br>
                    <span>Enhanced Living</span>
                  </span>
                  <span className="ad-us-text15 copy">
                    Enhance your living environment with a beautifully crafted
                    extension, perfect for guests, family, or rental income.
                    Invest in a luxurious space that offers both style and
                    practicality
                  </span>
                </div>
              </div>
            </animate-on-reveal>
          </div>
        </div>
      </div>
      <div className="ad-us-guide">
        <div className="ad-us-max-width2">
          <div className="ad-us-image1"></div>
          <div className="ad-us-float">
            <div className="ad-us-content1">
              <span className="ad-us-prehead2 preHead">guide</span>
              <span className="ad-us-text16">
                <span>Versatile living with ADU</span>
                <span className="ad-us-text18">s</span>
              </span>
              <span className="ad-us-text19">
                <span>
                  An
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="ad-us-text21">ADU</span>
                <span>
                  , or
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="ad-us-text23">Accessory Dwelling Unit</span>
                <span>
                  , is a self-contained living space located on the same
                  property as a single-family home. They include essential
                  amenities such as a kitchen, bathroom, and living area, making
                  them fully livable
                </span>
              </span>
              <span className="ad-us-text25">
                <span>
                  These versatile spaces can serve various purposes and bring
                  significant benefits to homeowners including:
                </span>
                <br></br>
              </span>
              <div className="ad-us-bullet-point1">
                <span className="ad-us-text28">
                  <span>• </span>
                  <br></br>
                </span>
                <span className="ad-us-text31">
                  <span className="ad-us-text32">
                    Increased Property Value:
                  </span>
                  <span> Boost your home’s market appeal and value.</span>
                  <br></br>
                </span>
              </div>
              <div className="ad-us-bullet-point2">
                <span className="ad-us-text35">
                  <span>• </span>
                  <br></br>
                </span>
                <span className="ad-us-text38">
                  <span className="ad-us-text39">Additional Income:</span>
                  <span>
                    {' '}
                    Rent out the space for a steady stream of income.
                  </span>
                  <br></br>
                </span>
              </div>
              <div className="ad-us-bullet-point3">
                <span className="ad-us-text42">
                  <span>• </span>
                  <br></br>
                </span>
                <span className="ad-us-text45">
                  <span className="ad-us-text46">Flexible Living Options:</span>
                  <span>
                    {' '}
                    Perfect for guests, family members, or as a home office.
                  </span>
                  <br></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ad-us-types">
        <div className="ad-us-heading-container2">
          <span className="ad-us-prehead3 Prehead">Options</span>
          <h1 className="ad-us-text49 SectionHeading">Find your perfect fit</h1>
        </div>
        <div className="ad-us-value-container">
          <div className="ad-us-container2">
            <div className="ad-us-head1">
              <img
                alt="image"
                src="/icons/ADUs/basement-200h.png"
                className="ad-us-icon1"
              />
              <span className="ad-us-text50">Junior ADUs</span>
            </div>
            <span className="ad-us-text51 copy">
              JADUs are compact, cost-effective units, typically smaller than
              500 square feet, often converted from existing spaces, such as
              garages or basements. Ideal for guests or home offices, they may
              lack the privacy and amenities of larger ADUs.
            </span>
          </div>
          <div className="ad-us-container3">
            <div className="ad-us-head2">
              <img
                alt="image"
                src="/icons/ADUs/home1-200h.png"
                className="ad-us-icon2"
              />
              <span className="ad-us-text52">Attached ADUs</span>
            </div>
            <span className="ad-us-text53 copy">
              AADUs are additional living spaces connected to the main
              house sharing utilities which reduces costs. They are affordable
              and convenient but may impact the privacy of the main home, ideal
              for expanding living space while staying close to family.
            </span>
          </div>
          <div className="ad-us-container4">
            <div className="ad-us-head3">
              <img
                alt="image"
                src="/icons/ADUs/living1-200h.png"
                className="ad-us-icon3"
              />
              <span className="ad-us-text54">Detached ADUs</span>
            </div>
            <span className="ad-us-text55 copy">
              DADUs are independent living spaces offering unmatched privacy and
              customization options. They are ideal for long-term rentals but
              come with higher costs and zoning considerations, maximizing
              property use.
            </span>
          </div>
        </div>
      </div>
      <div className="ad-us-material-grid">
        <animate-on-reveal
          animation="fadeIn"
          duration="1000ms"
          delay="0s"
          direction="normal"
          easing="ease-out"
          iteration="1"
        >
          <div
            data-thq-animate-on-reveal="true"
            className="ad-us-heading-container3"
          >
            <span className="preHead Prehead ad-us-prehead4">Inspiration</span>
            <h1 className="ad-us-text56 SectionHeading heading">
              <span>Discover your vision</span>
              <br></br>
            </h1>
          </div>
        </animate-on-reveal>
        <div className="ad-us-type-container">
          <div className="ad-us-row1">
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_1100222305.jpeg"
              className="ad-us-image2"
            />
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_1034173999.jpeg"
              className="ad-us-image3"
            />
          </div>
          <div className="ad-us-row2">
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_810846163.jpeg"
              className="ad-us-image4"
            />
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_814541058.jpeg"
              className="ad-us-image5"
            />
          </div>
          <div className="ad-us-row3">
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_1108272167.jpeg"
              className="ad-us-image6"
            />
            <img
              alt="image"
              src="/photos/adus/photos/adobestock_898625287.jpeg"
              className="ad-us-image7"
            />
          </div>
        </div>
      </div>
      <div className="ad-us-cta">
        <div className="ad-us-max-width3">
          <div className="ad-us-image8"></div>
          <div className="ad-us-content2">
            <div className="ad-us-content3">
              <h1 className="ad-us-text59 SectionHeading heading">
                invest in your future
              </h1>
              <span className="ad-us-text60 copy">
                <span>Ready to elevate your property?</span>
                <br></br>
                <span>Explore your options and discover how we can help.</span>
              </span>
              <Link to="/contact" className="Content bluebutton">
                <span>Schedule a consultation</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer
        link11={
          <Fragment>
            <span className="ad-us-text65">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="ad-us-text66">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="ad-us-text67">
              <span className="ad-us-text68">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="ad-us-text70">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="ad-us-text71">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="ad-us-text72">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="ad-us-text75">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="ad-us-text76">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="ad-us-text77">
              <span className="ad-us-text78">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-1000w.png"
        text1311={
          <Fragment>
            <span className="ad-us-text80">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
      ></Footer>
    </div>
  )
}

export default ADUs
