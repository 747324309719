import React, { Fragment } from 'react'
import Wrapper from '../components/page-wrapper'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContactPopup from '../components/contact-popup'
import Footer from '../components/footer'
import './contact.css'

const Contact = (props) => {
  let hook = {
    run: ()=>{}
  }

  const handleSubmit = async (event) => {
    //prevent changing url
    event.preventDefault();

    // Get form data
    const formData = new FormData(event.target);

    // Convert to a more readable format if needed, like an object
    const data = {
      name: formData.get('name'),
      phone: formData.get('phone'),
      email: formData.get('email'),
      message: formData.get('message'),
      street: formData.get('street'),
      city: formData.get('city'),
      zip: formData.get('zip'),
    };

    //send request to server
    const response = await fetch(window.location.protocol+'//'+window.location.host+'/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    //run hook to set popup to visible
    if(response.ok){
      hook.run();
    }

  }

  return (
    <Wrapper>
    <div className="contact-container10">
      <Helmet>
        <title>Contact - Craft & Forge - Premium Home Remodeling</title>
        <meta
          name="description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:title"
          content="Contact - Craft &amp; Forge - Premium Home Remodeling"
        />
        <meta
          property="og:description"
          content="Craft &amp; Forge offers high-end remodeling with a seamless process from consultation to completion. Discover expert craftsmanship and stunning transformations."
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/133b66a8-437a-4fe1-8938-2c01fd5c558d/c7ed6f96-1e17-4876-a525-ef1747a6b890?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <Navbar imageSrc1="https://play.teleporthq.io/static/svg/placeholders/no-image.svg"></Navbar>
      <div className="contact-hero-fade">
        <div className="contact-hero">
          <div className="contact-max-width1">
            <div className="contact-container11">
              <span className="contact-prehead preHead">
                <span>connect with us</span>
                <br></br>
              </span>
              <h1 className="contact-text12 Heading">
                Bring your vision to life
              </h1>
              <span className="copy contact-text13">
                <span>
                  We’re excited to hear about your project! Whether you have a
                </span>
                <br></br>
                <span>
                  clear vision
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>
                  or need expert guidance, Craft &amp; Forge is here to
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>
                  make your remodeling journey
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span>seamless and enjoyable.</span>
              </span>
              <span className="contact-text21">
                <span>
                  We’re excited to hear about your project! Whether you have a
                  clear vision 
                </span>
                <span>
                  or need expert guidance, Craft &amp; Forge is here to make
                  your remodeling journey 
                </span>
                <span>seamless and enjoyable. Contact us to get started.</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-contact">
        <div className="contact-max-width2">
          <div className="contact-contact-page-form-container">
            <form
              action="contact"
              method="POST"
              enctype="application/x-www-form-urlencoded"
              className="contact-form"
              onSubmit={handleSubmit}
            >
              <div className="contact-container12">
                <img
                  alt="image"
                  src="/icons/contact/user1-200h.png"
                  className="contact-image1"
                />
                <div className="contact-input1 insetInputField">
                  <input
                    type="text"
                    id="contact-form-8-first-name"
                    name="name"
                    required="true"
                    placeholder="Name"
                    className="contact-text-input1 Content"
                  />
                </div>
              </div>
              <div className="contact-container13">
                <img
                  alt="image"
                  src="/icons/contact/envelope1-200h.png"
                  className="contact-image2"
                />
                <div className="contact-input2 insetInputField">
                  <input
                    type="email"
                    id="contact-form-8-email"
                    name="email"
                    required="true"
                    placeholder="Email"
                    className="contact-text-input2 Content"
                  />
                </div>
              </div>
              <div className="contact-container14">
                <img
                  alt="image"
                  src="/icons/contact/phone-flip1-200h.png"
                  className="contact-image3"
                />
                <div className="contact-input3 insetInputField">
                  <input
                    type="tel"
                    id="contact-form-8-phone"
                    name="phone"
                    required="true"
                    placeholder="Phone Number"
                    className="contact-text-input3 Content"
                  />
                </div>
              </div>
              <div className="contact-container15">
                <img
                  alt="image"
                  src="/icons/contact/maps-and-flags-200h.png"
                  className="contact-image4"
                />
                <div className="contact-container16">
                  <div className="contact-input4 insetInputField">
                    <input
                      type="text"
                      id="contact-form-9-address"
                      name="street"
                      required="true"
                      placeholder="Street Address"
                      className="contact-text-input4 Content"
                    />
                  </div>
                  <div className="contact-container17">
                    <div className="contact-input5 insetInputField">
                      <input
                        type="text"
                        id="contact-form-10-city"
                        name="city"
                        required="true"
                        placeholder="City"
                        className="contact-text-input5 Content"
                      />
                    </div>
                    <div className="contact-input6 insetInputField">
                      <input
                        type="text"
                        id="contact-form-11-city"
                        name="zip"
                        required="true"
                        placeholder="Zip"
                        className="contact-text-input6 Content"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-container18">
                <img
                  alt="image"
                  src="/icons/contact/messages1-200h.png"
                  className="contact-image5"
                />
                <div className="contact-input7 insetInputField">
                  <textarea
                    id="contact-form-8-message"
                    name="message"
                    rows="4"
                    placeholder="Enter your message"
                    className="contact-textarea Content"
                  ></textarea>
                </div>
              </div>
              <div className="contact-container19">
                <button
                  type="submit"
                  className="contact-button bluebutton Content"
                >
                  <span>
                    <span>Submit</span>
                    <br></br>
                  </span>
                </button>
              </div>
            </form>
          </div>
          <div className="contact-heading-container">
            <div className="contact-photo-container">
              <div className="contact-column1">
                <div className="contact-photo-card1">
                  <img
                    alt="image"
                    src="/photos/contact/275044841_3024970754410922_1379415332540395219_n.jpg"
                    className="contact-image6"
                  />
                </div>
                <div className="contact-photo-card2">
                  <img
                    alt="image"
                    src="/photos/contact/179279947_363488748424937_4018633155401013782_n.jpg"
                    className="contact-image7"
                  />
                </div>
              </div>
              <div className="contact-column2">
                <div className="contact-photo-card3">
                  <img
                    alt="image"
                    src="/photos/contact/188091003_856557105072393_4202069016629068525_n.jpg"
                    className="contact-image8"
                  />
                </div>
                <div className="contact-photo-card4">
                  <img
                    alt="image"
                    src="/photos/contact/image6%20(1)-min_edited%20(1)-1000w.jpg"
                    className="contact-image9"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactPopup rootClassName="contact-popuproot-class-name1" popupHook={hook}></ContactPopup>
      <Footer
        link11={
          <Fragment>
            <span className="contact-text28">Full Floor Remodels</span>
          </Fragment>
        }
        text10={
          <Fragment>
            <span className="contact-text29">
              Follow us on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </Fragment>
        }
        text14={
          <Fragment>
            <span className="contact-text30">
              <span className="contact-text31">Home</span>
              <br></br>
            </span>
          </Fragment>
        }
        text24={
          <Fragment>
            <span className="contact-text33">About</span>
          </Fragment>
        }
        text34={
          <Fragment>
            <span className="contact-text34">Contact</span>
          </Fragment>
        }
        text91={
          <Fragment>
            <span className="contact-text35">
              <span>Custom Home Builds</span>
              <br></br>
            </span>
          </Fragment>
        }
        text111={
          <Fragment>
            <span className="contact-text38">Kitchen Remodels</span>
          </Fragment>
        }
        text211={
          <Fragment>
            <span className="contact-text39">Bathroom Remodels</span>
          </Fragment>
        }
        text311={
          <Fragment>
            <span className="contact-text40">
              <span className="contact-text41">House Additions</span>
              <br></br>
            </span>
          </Fragment>
        }
        footerId="Footer"
        imageSrc="/logos/logo%20dark-200h.png"
        text1311={
          <Fragment>
            <span className="contact-text43">
              ©Craft and Forge 2024 All rights reserved
            </span>
          </Fragment>
        }
        rootClassName="footerroot-class-name"
      ></Footer>
    </div>
    </Wrapper>
  )
}

export default Contact
