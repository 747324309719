import {
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import RenovationsAdditions from './views/renovations-additions'
import Kitchens from './views/kitchens'
import CustomHomes from './views/custom-homes'
import About from './views/about'
import Bathrooms from './views/bathrooms'
import Pallette from './views/pallette'
import Contact from './views/contact'
import HomeTest from './views/home-test'
import Home from './views/home'
import ADUs from './views/ad-us'
import NotFound from './views/not-found'

export default () => {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        <Route
          component={RenovationsAdditions}
          exact
          path="/renovations-additions"
        />
        <Route component={Kitchens} exact path="/kitchens" />
        <Route component={CustomHomes} exact path="/custom-homes" />
        <Route component={About} exact path="/about" />
        <Route component={Bathrooms} exact path="/bathrooms" />
        <Route component={Pallette} exact path="/pallette" />
        <Route component={Contact} exact path="/contact" />
        <Route component={ADUs} exact path="/adus" />
        <Route component={HomeTest} exact path="/home-test" />
        <Route component={Home} exact path="/" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </AnimatePresence>
  );
}


